import { combineReducers } from 'redux';
import errors from './errorsReducer';
import steps from './stepsReducer';
import agent from './agentReducer';

/**
 * Mise en commun de l'ensemble des reducers
 * Tout nouveau reducer doit dont y etre ajouté
 */
export default combineReducers({
  steps,
  agent,
  errors,
});
