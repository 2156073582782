import React from 'react';
import Header from 'components/Header/Header';
import { getProperty } from 'utils/config';

const Version = () => {
  const v = getProperty('version');
  return (
    <>
      <Header />
      <main>
        <section className="container">
          <div className="unavailable-page">
            <h1>Version</h1>
            {v}
          </div>
        </section>
      </main>
    </>
  );
};

export default Version;
