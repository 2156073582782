import React, { PureComponent } from 'react';
import curry from 'lodash/curryRight';

function withGlobalState(Template, initialState) {
  class Hoc extends PureComponent {
    constructor(props) {
      super(props);
      this.state = {
        ...initialState,
      };
    }

    render() {
      return (
        <Template
          {...this.props}
          {...this.state}
        />
      );
    }
  }

  Hoc.displayName = `${Template.name}withGlobalState`;

  return Hoc;
}

export default curry(withGlobalState);
