export const KEY_TAB = 9;
export const KEY_ESC = 27;

export const DEFAULT_CONTRAST = 'default';
export const REINFORCED_CONTRAST = 'reinforced';
export const INVERTED_CONTRAST = 'inverted';

export const INCREASED_SPACING_CLASS = 'higher-spacing';
export const ADAPTED_FONT_NAME = 'OpenDyslexic';

export const DEFAULT_FONT = 'default';
export const ADAPTED_FONT = 'adapted';
export const ADAPTED_FONT_FAMILY = 'Times New Roman';

export const DEFAULT_SPACING = 'default';
export const INCREASED_SPACING = 'increased';

export const TOOL_BLOCK_WORDINGS_IDS = {
  [DEFAULT_CONTRAST]: 'Default',
  [REINFORCED_CONTRAST]: 'Reinforce',
  [INVERTED_CONTRAST]: 'Inverse',
  [INCREASED_SPACING]: 'Increase',
  [ADAPTED_FONT]: 'Adapted',
};
