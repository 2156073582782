import {
  fetchPaymentCheck, receivePaymentCheck,
} from 'actions/PaymentCheck';
import { CLEAR_ERROR } from '../../../actions/ActionTypes';


/**
 * Clear error action creator.
 * @param {function} dispatch
 * @returns {function()} the bound clear error action creator.
 */
export const clearError = (dispatch) => () => (
  dispatch({ type: CLEAR_ERROR })
);

export const fetchTools = (dispatch) => (authorization, endpoint, timeout) => {
  fetchPaymentCheck(
    authorization,
    dispatch,
    endpoint,
    timeout,
  );
};

export const handleError = (dispatch) => (error, intl) => {
  let dispatcher;
  if (
    (error.status > 499 && error.status < 510)
    || error.status === undefined
  ) {
    dispatch(dispatcher(error, intl));
  } else if (
    error.status === 400
    || error.status === 404
    || error.status === 403) {
    dispatch(receivePaymentCheck(error));
  }
};
