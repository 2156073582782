import isArray from 'lodash/isArray';

/**
 * A function that tests if an input is false or can be converted to false.
 * @function isFalse
 * @param {*} input
 * @returns {boolean}
 */
export const isFalse = (input) => !input;

/**
 * A function that tests if an input is undefined or null.
 * @function isNullOrUndefined
 * @param {*} input
 * @returns {boolean}
 */
export const isNullOrUndefined = (input) => typeof input === 'undefined' || input === null;

/**
 * A function that tests if an array has undefined or null.
 * @function hasEmptyValue
 * @param {*} input
 * @returns {boolean}
 */
export const hasEmptyValue = (input) => !isArray(input) || !input.length || input.includes(isFalse);

/**
 * A function that returns a deeply nested property in an object.
 * @function init
 * @param {function} fn the function returning the value.
 * @param {*} def the default value.
 * @param {function(*): boolean} isEmpty a function to test if the returned value is empty.
 * @returns {*} the value returned by fn or def.
 */
export default (fn, def = '', isEmpty = isFalse) => {
  let value;
  try {
    value = fn();
    if (isEmpty(value)) {
      value = def;
    }
  } catch (e) {
    value = def;
  }
  return value;
};
