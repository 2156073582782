import React, { PureComponent } from 'react';
import noop from 'lodash/noop';
import curryRight from 'lodash/curryRight';
import { string } from 'prop-types';

const noopStyle = {
  use: noop,
  unuse: noop,
};

function withStyleSwitch(Template, styles, keyId) {
  class Hoc extends PureComponent {
    componentDidMount() {
      this.getCurrentStyle().use();
    }

    componentDidUpdate(prevProps) {
      if (this.props[keyId] !== prevProps[keyId]) {
        styles[prevProps[keyId]].unuse();
        this.getCurrentStyle().use();
      }
    }

    componentWillUnmount() {
      this.getCurrentStyle().unuse();
    }

    getCurrentStyle = styles ? () => styles[this.props[keyId]] || noopStyle : () => noopStyle;

    static setCurrentStyle = (prevStyle, newStyle) => {
      styles[prevStyle].unuse();
      styles[newStyle].use();
    };

    render() {
      return (<Template {...this.props} setStyle={Hoc.setCurrentStyle} />);
    }
  }

  Hoc.displayName = `${Template.name}WithStyleSwitch`;

  Hoc.propTypes = {
    [keyId]: string.isRequired,
  };

  return Hoc;
}

export default curryRight(withStyleSwitch);
