import {
  ERR_404, ERR_401, ERR_503, ERR_504, CLEAR_ERROR, SEARCH_ERR_EMPTY,
} from 'actions/ActionTypes';

/**
 * Reducer pour le traitement des erreurs
 */
// eslint-disable-next-line default-param-last
export default function errorsReducer(state = {}, action) {
  switch (action.type) {
    case ERR_404:
    case ERR_401:
    case ERR_503:
    case ERR_504:
      return { ...state, [action.type]: action.error };
    case SEARCH_ERR_EMPTY:
      return { ...state, search: action };
    case CLEAR_ERROR:
      return { ...state, error: null };
    default:
      return state;
  }
}
