import axios from 'axios';

function fetchExternalConfiguration(path) {
  return axios.get(`/resources/${path}`).then((resp) => resp.data);
}

/**
 * Fetch a json configuration path into an object. Either from a local path
 * if running in local mode, or from a distant url if running in production mode
 * @param path the path containing the json configuration
 * @param env
 * @returns Promise of the configuration as an object
 */
export default function fetchConfiguration(path, env) {
  if (env && env === 'external') {
    return fetchExternalConfiguration(path);
  }
  return new Promise((resolve) => {
    let configuration = {};
    // eslint-disable-next-line global-require, import/no-dynamic-require
    configuration = Object.freeze({ ...configuration, ...require(`../../resources/${path}`) });
    resolve(configuration);
  });
}
