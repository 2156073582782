import $ from 'jquery';

const calcBannersHeight = () => {
  let ht = 0;
  // eslint-disable-next-line func-names
  $('.banner-crisis').each(function () {
    ht += $(`#${this.id}`).outerHeight();
  });
  // eslint-disable-next-line func-names
  $('.banner-kidnapping').each(function () {
    ht += $(`#${this.id}`).outerHeight();
  });
  return ht;
};

const updateStickyTabsHeight = () => {
  if ($('.top-header-sticky').length !== 0) {
    let ht = calcBannersHeight();
    $('.top-header-sticky').css({ top: `${ht}px` });
    ht += $('.js-be-sticky').outerHeight();
    $('#main-content').css({ 'margin-top': `${ht}px` });
  }
};

const initHeader = () => {
  if (window.pageYOffset.valueOf > 30) {
    $('.bar-header-languages-accessibility .js-to_expand').addClass('accessibility-scroll');
    if ($('.js-be-sticky').length !== 0) {
      // If subhome, tabs are sticky.
      $('.js-be-sticky').addClass('top-header-sticky');
      updateStickyTabsHeight();
      $('.main-header').hide();
    } else {
      $('#main-content').css({ 'margin-top': '8rem' });
    }
    $('.header-for-sticky').addClass('snc-header-sticky');
  } else {
    // If not subhome, header is sticky.
    $('.bar-header-languages-accessibility .js-to_expand').removeClass('accessibility-scroll');
    if ($('.js-be-sticky').length !== 0) {
      $('.js-be-sticky').removeClass('top-header-sticky');
      $('.main-header').show();
    }
    $('.header-for-sticky').removeClass('snc-header-sticky');
    $('#main-content').css({ 'margin-top': '0' });
    $('.top-header-sticky').css({ top: '0' });
  }
  $(window).trigger('anchorScroll');
};

export default { initHeader };
