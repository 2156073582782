/**
 * The connected component of Step1.
 */
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';
import Resume from 'pages/step/Resume/Resume';
import hoc from 'pages/step/Resume/ResumeHOC';


function mapStateToProps(state, props) {
  return {
    lang: props.lang,
    handleLang: props.handleLang,
    handleStyle: props.handleStyle,
  };
}


export default connect(mapStateToProps)(injectIntl(hoc(Resume)));
